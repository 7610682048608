import React, { ChangeEvent, useState } from 'react';

import {
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_RATING_STATUS,
  USER_REVIEW_QUERY_PARAMS,
  TASK_TYPE,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ButtonVariant } from '~/components/Buttons';
import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { PlanGoalQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/PlanGoalQuestion';
import { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';
import { SkillAnswers } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswers';
import { NominatePeers } from '~/pages/Reviews/Modals/NominatePeers';
import { PlanCalendarEventModal } from '~/pages/Reviews/Modals/PlanCalendarEventModal';
import { SelectCoachesModal } from '~/pages/Reviews/Modals/SelectCoachesModal';
import { SelectGuestsModal } from '~/pages/Reviews/Modals/SelectGuestsModal';

import { Attachments } from './components/Attachments';
import { DashboardBanner } from './components/DashboardBanner';
import { NextSteps } from './components/NextSteps';
import { NextStepsModal } from './components/NextStepsModal';
import { QuestionEditModal } from './components/QuestionEditModal';
import { RatingAnswers } from './components/Questions/RatingAnswers';
import { SkillAnswersV1 } from './components/Questions/SkillAnswersV1';
import { TextAnswers } from './components/Questions/TextAnswers';
import { QuestionWrapper } from './components/QuestionWrapper';
import { Scores } from './components/Scores';
import { SignatureModal } from './components/SignatureModal';
import { Signatures } from './components/Signatures';
import { StatusLabel } from './components/StatusLabel';
import { Summaries } from './components/Summaries';
import { SummaryModal } from './components/SummaryModal';
import { Divider, EndOfTheReport, QuestionListContainer, ReviewDashboardTitle } from './design';
import { useAttachments } from './hooks/useAttachments';
import { useNextSteps } from './hooks/useNextSteps';
import { useReviewRatings, type IUserReviewQuestionGrouped } from './hooks/useReviewRatings';
import { useSignatures } from './hooks/useSignatures';
import { useSummary } from './hooks/useSummary';
import { useUserPreview } from './hooks/useUserPreview';
import { useUserReview } from './hooks/useUserReview';
import { DetailsSection } from './sidebarLeft/sections/Details';
import { EmployeeTasksSidebarSection } from './sidebarLeft/sections/EmployeeTasks';
import { OthersTasksSidebarSection } from './sidebarLeft/sections/OthersTasks';
import { SignaturesSection } from './sidebarLeft/sections/Signatures';
import {
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionCustomSkillV1Grouped,
  IUserReviewQuestionSkillCategoryGrouped,
  IUserReviewQuestionSkillCategoryV1Grouped,
} from './utils';

// TODO: Disable the Edit button in User Review Dashboard
// import { STATUS } from '~/constants/statusProvider';
import useBoolState from '~/hooks/useBoolState';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { useQueryURL } from '~/hooks/useQueryURL';
import { getUser } from '~/selectors/baseGetters';
import { createOrUpdateReviewRatings } from '~/services/reviewRatings';
import { downloadUserReviewPdf } from '~/services/userReviews';
import { COLORS } from '~/styles';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { DashboardLayout } from '../../components/DashboardLayout';

import type { AnswerType, IAnswer, IReviewDashboardUserForm } from './types';
import type {
  IGoal,
  IReviewQuestion,
  IReviewRating,
  IReviewTheme,
  ITask,
  IUserReview,
} from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface ReviewCycleFormProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  languageState: ILanguageStateReturn;
}

export const isZeroState = (question: IUserReviewQuestionGrouped) => {
  switch (question.type) {
    case REVIEW_QUESTION_TYPES.TEXT:
      return question.reviewRatings?.filter((rating) => Boolean(rating.answer)).length === 0;
    case REVIEW_QUESTION_TYPES.RATING:
      return (
        question.reviewRatings?.filter((rating) => rating.answer !== -1 && Boolean(rating.answer))
          .length === 0
      );
    case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
    case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1:
      return (
        question.skills.filter(
          (skill) =>
            skill.questions.filter((question) => {
              const ratings = question.reviewRatings?.filter(
                (rating) => rating.answer !== -1 && Boolean(rating.answer),
              );

              return ratings && ratings?.length > 0;
            })?.length > 0,
        )?.length === 0
      );
    case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
    case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1:
      return (
        question.skills.filter(
          (skill) =>
            skill.questions.filter((question) => {
              const ratings = question.reviewRatings?.filter(
                (rating) => rating.answer !== -1 && Boolean(rating.answer),
              );

              return ratings && ratings?.length > 0;
            })?.length > 0,
        )?.length === 0
      );
    default:
      return false;
  }
};

const ReviewDashboardUserForm = ({ formMethods }: ReviewCycleFormProps) => {
  const { i18n } = useLingui();
  const currentUser = useSelector(getUser);
  const params: Record<string, string | undefined> = useParams();

  const customQueryParams = useQueryURL({
    keys: [USER_REVIEW_QUERY_PARAMS.SHOW_PLAN_CONVERSATION_MODAL],
  });

  const userReviewId = params.userReviewId as string;
  const { addToast } = useToasts();

  const [selectedRating, setSelectedRating] = useState<{
    questionIndex: number;
    reviewRating?: AnswerType;
    question: IUserReviewQuestionGrouped;
  } | null>(null);

  const $openEditModal = useBoolState(false);
  const getMultiLangString = useMultiLangString();
  const { watch } = formMethods;
  const [loadingPDF, setLoadingPDF] = useState(false);

  const nominatePeersTask = watch('taskNominatePeers');
  const employee = watch('employee');

  const {
    userReview,
    isLoading,
    isEmployee,
    employeeFirstName,
    // delete
    isShowToDelete,
    isDisableToDelete,
    // edit
    isShowToEdit,
    isDisableToEdit,
    // archive
    isAllowToArchive,
    isAllowToUnarchive,
    // export PDF
    // isAllowToExportPDF,
    // sign
    isShowToSign,
    isDisableToSign,
    isDigitalSign,
    onEdit,
    onDelete,
    onArchive,
    onUnarchive,
    // onExportPDF,
    isAllowToEditSummaryOrNextSteps,
    isAllowToGiveSelfReview,
    isAllowToNominatePeers,
    isAllowToGiveCoachReview,
    isAllowToGivePeerReview,
    showNominatePeersModal,
    openNominatePeersModal,
    closeNominatePeersModal,
    onGiveSelfReview,
    onGiveCoachReview,
    isAllowToDeletePeers,
    isAllowToDeleteCoaches,
    isAllowToDeleteGuests,
    isAllowToAddPeers,
    isAllowToAddCoaches,
    isAllowToAddGuests,
    isAllowToPlanConversation,
    isAllowedToAccessMeetingLink,
    onDeleteCoach,
    onDeletePeer,
    onDeleteGuest,
    onAddCoaches,
    onAddGuests,
    showSelectCoachesModal,
    openSelectCoachesModal,
    closeSelectCoachesModal,
    showSelectGuestsModal,
    openSelectGuestsModal,
    closeSelectGuestsModal,
    showPlanCalendarEventModal,
    openPlanCalendarEventModal,
    closePlanCalendarEventModal,
    isAllowToSign,
    isAllowToResetSignatures,
    isAdmin,
    isEmployeeQuestions: _isEmployeeQuestions,
    isPeerQuestions,
    isCoachQuestions,
    refetch,
    isCreator,
    isInputCoach,
    goToReviews,
    setPlaceholderStatus,
    showActiveTaskBanner,
    showLockReviewBanner,
    showCoachCalibrateBanner,
    showNoGuestsToSignBanner,
    onPublishCalibrateRatings,
  } = useUserReview({
    formMethods,
    userReviewId,
  });

  const isShowOverallRating = watch('settings')?.isShowOverallRating;

  const { showUserPreviewDrawer } = useUserPreview({
    isAdmin,
    isEmployee,
    isInputCoach,
    formMethods,
  });

  const { reviewRatings } = useReviewRatings({
    userReview,
  });

  const participants = uniq([watch('employee.id'), ...watch('coaches'), ...watch('guests')]);

  const summaryModal = useSummary({
    userReviewId: userReview?.id,
    status: watch('status'),
    isAdmin,
    isCreator,
    isEmployee,
    isInputCoach,
  });
  const nextStepsModal = useNextSteps({
    userReviewId: userReview?.id,
    status: watch('status'),
    isAdmin,
    isCreator,
    isEmployee,
    isInputCoach,
  });
  const attachmentHook = useAttachments({
    userReviewId: userReview?.id,
    formMethods,
    status: watch('status'),
    isAdmin,
    isCreator,
    isEmployee,
    isInputCoach,
  });
  const signatureModal = useSignatures({
    employeeId: watch('employee.id'),
    isLoading,
    userReviewId: userReview?.id,
    status: userReview?.status,
    signatures: userReview?.signatures,
    isEmployee,
    forceReFetch: refetch.toggle,
    setPlaceholderStatus,
  });

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));

  const iconButtons = [
    isShowToEdit && {
      icon: ICONS.EDIT_PENCIL,
      onClick: (_e: ChangeEvent<HTMLInputElement>) => onEdit(),
      isLoading,
      disabled: isDisableToEdit,
      tooltip: isDisableToEdit
        ? i18n._(t`This review is part of a review cycle and can only be edited there`)
        : i18n._(t`Edit`),
    },
    isAllowToArchive && {
      icon: ICONS.ARCHIVE,
      onClick: onArchive,
      isLoading,
      tooltip: i18n._(t`Archive`),
    },
    isAllowToUnarchive && {
      icon: ICONS.UNARCHIVE,
      onClick: onUnarchive,
      isLoading,
      tooltip: i18n._(t`Unarchive`),
    },
    isShowToDelete && {
      icon: ICONS.DELETE_BIN,
      onClick: onDelete,
      isLoading,
      disabled: isDisableToDelete,
      tooltip: isDisableToDelete
        ? i18n._(
            t`This review is part of a review cycle. To delete the review, delete the employee there.`,
          )
        : i18n._(t`Delete`),
    },
    userReview &&
      [
        USER_REVIEW_STATUS.PUBLISHED,
        USER_REVIEW_STATUS.ACTIVE,
        USER_REVIEW_STATUS.SIGNING,
        USER_REVIEW_STATUS.COMPLETED,
        USER_REVIEW_STATUS.ARCHIVED,
      ].includes(userReview?.status) && {
        icon: ICONS.EXPORT,
        onClick: async () => {
          try {
            setLoadingPDF(true);
            addToast({
              title: i18n._(t`Exporting PDF`),
              subtitle: i18n._(
                t`Your PDF is being prepared for download. This may take a moment. It will start downloading once ready.`,
              ),
              type: TOAST_TYPES.INFO,
            });
            await downloadUserReviewPdf(
              userReviewId || '',
              getMultiLangString(userReview?.name),
              employeeFirstName,
            );
          } finally {
            setLoadingPDF(false);
          }
        },
        isLoading: loadingPDF || isLoading,
        tooltip: i18n._(t`Export PDF`),
      },
  ].filter((i) => i) as unknown as HeaderIconButtons[];

  const sidebarConfig = {
    items: [
      {
        title: isEmployee ? i18n._(t`Your tasks`) : i18n._(t`${employeeFirstName}'s tasks`),
        icon: ICONS.CHECKBOX_2,
        content: (
          <EmployeeTasksSidebarSection
            tasks={watch('tasksSelf')}
            employeeFirstName={employeeFirstName}
            isEmployee={isEmployee}
            isAllowToGiveSelfReview={isAllowToGiveSelfReview}
            isAllowToNominatePeers={isAllowToNominatePeers}
            onGiveSelfReview={onGiveSelfReview}
            onNominatePeers={openNominatePeersModal}
          />
        ),
        isContentVisibleByDefault: true,
      },
      (isPeerQuestions || isCoachQuestions) && {
        title: i18n._(t`Input by others`),
        icon: ICONS.USER_CHECK,
        content: (
          <OthersTasksSidebarSection
            tasksPeers={watch('tasksReceivedPeers')}
            tasksCoaches={watch('tasksReceivedCoaches')}
            formMethods={formMethods}
            isAllowToDeletePeers={isAllowToDeletePeers}
            isAllowToDeleteCoaches={isAllowToDeleteCoaches}
            isAllowToAddPeers={isAllowToAddPeers}
            isAllowToAddCoaches={isAllowToAddCoaches}
            onDeletePeer={onDeletePeer}
            onDeleteCoach={onDeleteCoach}
            onAddPeers={openNominatePeersModal}
            onAddCoaches={openSelectCoachesModal}
            isAllowToGiveCoachReview={isAllowToGiveCoachReview}
            isAllowToGivePeerReview={isAllowToGivePeerReview}
            onGiveCoachReview={onGiveCoachReview}
            isPeerQuestions={isPeerQuestions}
            isCoachQuestions={isCoachQuestions}
          />
        ),
        isContentVisibleByDefault: true,
      },
      isDigitalSign
        ? {
            title: i18n._(t`Signatures`),
            icon: ICONS.SIGNATURES,
            content: (
              <SignaturesSection
                formMethods={formMethods}
                isAllowToSign={isAllowToSign}
                onSign={signatureModal.isVisible.on}
              />
            ),
          }
        : undefined,
      {
        title: i18n._(t`Review details`),
        icon: ICONS.DETAILS,
        content: (
          <DetailsSection
            formMethods={formMethods}
            onAddGuests={openSelectGuestsModal}
            onDeleteGuest={onDeleteGuest}
            isAllowToDeleteGuests={isAllowToDeleteGuests}
            isAllowToAddGuests={isAllowToAddGuests}
            isAllowToPlanConversation={isAllowToPlanConversation}
            isAllowedToAccessMeetingLink={isAllowedToAccessMeetingLink}
            onPlanConversation={openPlanCalendarEventModal}
            userReviewId={userReview?.id}
          />
        ),
        isContentVisibleByDefault:
          !!customQueryParams.values[USER_REVIEW_QUERY_PARAMS.SHOW_PLAN_CONVERSATION_MODAL],
      },
    ].filter((i) => i),
    buttons: [
      {
        title: i18n._(t`Show next steps`),
        icon: ICONS.ARROW_DOUBLE_RIGHT,
        color: COLORS.SKY_BLUE,
        disabled: !isAllowToEditSummaryOrNextSteps,
        tooltip: !isAllowToEditSummaryOrNextSteps ? nextStepsModal.openNextStepsTooltip : '',
        onClick: () => {
          nextStepsModal.isVisible.on();
        },
      },
      {
        title: i18n._(t`Show summary`),
        icon: ICONS.SUMMARY,
        color: COLORS.CYAN,
        disabled: !isAllowToEditSummaryOrNextSteps,
        tooltip: !isAllowToEditSummaryOrNextSteps ? summaryModal.openMySummaryTooltip : '',
        onClick: () => {
          summaryModal.isVisible.on();
        },
      },
    ],
  };

  const onEditClick = (
    params: {
      skill?:
        | IUserReviewQuestionSkillCategoryGrouped['skills'][0]
        | IUserReviewQuestionCustomSkillGrouped['skills'][0];
      question?: IQuestionDefaultData;
    },
    questionIndex: number,
    rating?: AnswerType,
  ) => {
    const { skill, question } = params;
    setSelectedRating({
      reviewRating: rating,
      question: { ...(question || {}), ...(skill || {}) } as unknown as IUserReviewQuestionGrouped,
      questionIndex,
    });
    $openEditModal.on();
  };

  const onEditModalClose = () => {
    setSelectedRating(null);
    $openEditModal.off();
  };

  const onUpdate = async (
    updatedAnswers: IAnswer[],
    goalsPlanned: IGoal['id'][],
    status: REVIEW_RATING_STATUS,
  ) => {
    let task: ITask | undefined;

    if (
      selectedRating?.question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY ||
      selectedRating?.question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL ||
      selectedRating?.question.type === REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1 ||
      selectedRating?.question.type === REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1
    ) {
      task = userReview?.tasks.find(
        ({ id }) =>
          'reviewRating' in selectedRating.question &&
          id === (selectedRating?.question?.reviewRating as Array<IReviewRating>)[0].task,
      );
    } else if (selectedRating?.question.type === REVIEW_QUESTION_TYPES.GOAL_PLAN) {
      task = userReview?.tasks.find((item) => item.userTo.id === currentUser.id);
    } else {
      task = userReview?.tasks.find(({ id }) => id === selectedRating?.reviewRating?.task);
    }

    if (task) {
      const reviewRatings =
        userReview?.userReviewQuestions
          .map((question) => {
            const reviewQuestion = question as unknown as IReviewQuestion & {
              reviewRatings?: (IReviewRating & { reviewType: string })[];
            };
            const ratings = reviewQuestion.reviewRatings?.filter(
              (rating) => rating.task === task?.id,
            );
            return ratings;
          })
          .filter(Boolean)
          .flat() || [];

      const transformedData = reviewRatings.map((rating) => {
        const reviewRating = rating as unknown as IReviewRating;
        const updatedAnswer = updatedAnswers.find(
          ({ questionId }) => questionId === reviewRating.userReviewQuestion,
        );

        let answer = null;
        let oldAnswer = null;

        if (!updatedAnswer?.isNotApplicable) {
          if (reviewRating.userReviewQuestion === updatedAnswer?.questionId) {
            answer = updatedAnswer.answer;
            oldAnswer = updatedAnswer.oldAnswer;
          } else {
            answer = reviewRating.answer;
            oldAnswer = reviewRating.oldAnswer;
          }
        }

        const comment =
          reviewRating.userReviewQuestion === updatedAnswer?.questionId
            ? updatedAnswer.comment
            : reviewRating.comment;
        return {
          id: reviewRating.id,
          company: task?.company,
          createdBy: task?.userTo,
          createdFor: task?.userFrom as string,
          userReview: reviewRating.userReview,
          userReviewQuestion: reviewRating.userReviewQuestion,
          answer,
          oldAnswer,
          comment,
          userReviewQuestionType: reviewRating.userReviewQuestionType,
        };
      });

      await createOrUpdateReviewRatings({
        taskId: task.id,
        status,
        // @ts-ignore
        reviewRatings: transformedData,
        goalsPlanned,
      });
    }
  };

  const onUpdateReviewRating = async (
    answer: IAnswer[],
    goalsPlanned?: IUserReview['goalsPlanned'],
    status?: REVIEW_RATING_STATUS,
  ) => {
    if (status) {
      await onUpdate(answer, goalsPlanned?.map((item) => item.id) || [], status);
      refetch.toggle();
      setSelectedRating(null);
      $openEditModal.off();
    }
  };

  /**
   * TODO: Disable the Edit button in User Review Dashboard
   */
  const isEditable = (_rating: AnswerType, _userReview: IUserReview) => {
    return false;
    // const ineligibleStatusList = [STATUS.SIGNING, STATUS.UPCOMING, STATUS.ARCHIVED];
    // return (
    //   rating.createdBy?.id === currentUser.id &&
    //   ineligibleStatusList.findIndex((status) => status === userReview.status) === -1
    // );
  };

  const getQuestion = (question: IUserReviewQuestionGrouped, questionIndex: number) => {
    switch (question.type) {
      case REVIEW_QUESTION_TYPES.TEXT:
        return (
          <TextAnswers
            userReview={userReview as IUserReview}
            isZeroState={isZeroState(question)}
            isEditable={(rating: AnswerType) => isEditable(rating, userReview as IUserReview)}
            question={question as unknown as IQuestionDefaultData}
            onEdit={(rating: AnswerType, question: IQuestionDefaultData) =>
              onEditClick({ question }, questionIndex, rating)
            }
          />
        );
      case REVIEW_QUESTION_TYPES.RATING:
        return (
          <RatingAnswers
            userReview={userReview as IUserReview}
            isZeroState={isZeroState(question)}
            isEditable={(rating: AnswerType) => isEditable(rating, userReview as IUserReview)}
            question={question as unknown as IQuestionDefaultData}
            onEdit={(rating: AnswerType, question: IQuestionDefaultData) =>
              onEditClick({ question }, questionIndex, rating)
            }
          />
        );
      case REVIEW_QUESTION_TYPES.GOAL_PLAN:
        return (
          <PlanGoalQuestion
            userFrom={userReview?.tasks?.[0]?.userFrom}
            userReview={userReview}
            goalsPlanned={userReview?.goalsPlanned}
            currentUser={currentUser}
            isDashboard
            onEdit={() =>
              onEditClick({ question: question as unknown as IQuestionDefaultData }, questionIndex)
            }
            subTypes={question.settings.subTypes}
          />
        );
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
        return (
          <SkillAnswers
            question={question}
            isZeroState={isZeroState(question)}
            onEdit={(
              skill:
                | IUserReviewQuestionCustomSkillGrouped['skills'][0]
                | IUserReviewQuestionSkillCategoryGrouped['skills'][0],
              question,
            ) => {
              onEditClick({ skill, question }, questionIndex);
            }}
            userReview={userReview as IUserReview}
            questionNumber={questionIndex + 1}
          />
        );
      case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1:
      case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1:
        return (
          <SkillAnswersV1
            question={question}
            isZeroState={isZeroState(question)}
            onEdit={(
              skill:
                | IUserReviewQuestionCustomSkillV1Grouped['skills'][0]
                | IUserReviewQuestionSkillCategoryV1Grouped['skills'][0],
              question,
            ) => {
              onEditClick({ skill, question }, questionIndex);
            }}
            userReview={userReview as IUserReview}
            questionNumber={questionIndex + 1}
          />
        );
    }
  };
  // Not show kebab menu on summary when
  // when digi sign is enabled: status signing, completed or archived
  // when digi sign disabled: status is archived
  const isNotKebabMenuOnSummaryEnabled = isDigitalSign
    ? [
        USER_REVIEW_STATUS.SIGNING,
        USER_REVIEW_STATUS.COMPLETED,
        USER_REVIEW_STATUS.ARCHIVED,
      ].includes(watch('status'))
    : watch('status') === USER_REVIEW_STATUS.ARCHIVED;
  return (
    <>
      <HeaderFocusMode
        title={i18n._(t`${getMultiLangString(nameMultiLang)}`)}
        goBack={goToReviews}
        iconButtons={iconButtons}
        status={<StatusLabel status={watch('status')} />}
        isFixed
        isBackIcon={true}
        submitButton={
          isShowToSign
            ? {
                title: i18n._(t`Sign`),
                type: ButtonVariant.PRIMARY,
                onClick: signatureModal.isVisible.on,
                disabled: isDisableToSign,
                tooltip: isDisableToSign
                  ? i18n._(t`You can sign the review after your coach has signed`)
                  : i18n._(t`Sign`),
              }
            : undefined
        }
      />
      <DashboardLayout
        userReviewId={userReview?.id}
        placeholderStatus={watch('placeholderStatus')}
        showUserPreviewDrawer={showUserPreviewDrawer}
        userId={watch('employee')?.id}
        isLoading={isLoading}
        // @ts-ignore
        sidebar={sidebarConfig}
        main={
          <>
            <DashboardBanner
              task={userReview?.tasks.find(
                (task) =>
                  currentUser.id &&
                  task?.userTo?.id &&
                  currentUser.id === task?.userTo?.id &&
                  task.type !== TASK_TYPE.REVIEW_PEER_NOMINATE,
              )}
              showLockReviewBanner={showLockReviewBanner}
              showActiveTaskBanner={showActiveTaskBanner}
              showCoachCalibrateBanner={showCoachCalibrateBanner}
              showNoGuestsToSignBanner={showNoGuestsToSignBanner}
              isLoading={isLoading}
              onArchive={onArchive}
              onGiveCoachReview={onGiveCoachReview}
              onGiveSelfReview={onGiveSelfReview}
              onPublishCalibrateRatings={onPublishCalibrateRatings}
              onAddGuests={openSelectGuestsModal}
            />
            <ReviewDashboardTitle>
              <Trans>The report</Trans>
            </ReviewDashboardTitle>
            <QuestionListContainer>
              {reviewRatings.map((question, index) => {
                return (
                  <QuestionWrapper
                    isZeroState={isZeroState(question)}
                    key={index}
                    questionNumber={index + 1}
                    themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
                    question={question}
                  >
                    {getQuestion(question, index + 1)}
                  </QuestionWrapper>
                );
              })}
            </QuestionListContainer>
            <Divider />
            <ReviewDashboardTitle>
              <Trans>Conclusions</Trans>
            </ReviewDashboardTitle>
            {isShowOverallRating && <Scores userReview={userReview} />}
            <Summaries
              reviewSummaries={summaryModal.reviewSummaries}
              onOpenMySummary={summaryModal.isVisible.on}
              isOpenMySummaryDisabled={!isAllowToEditSummaryOrNextSteps}
              openMySummaryTooltip={summaryModal.openMySummaryTooltip}
            />
            <NextSteps
              nextSteps={nextStepsModal.nextSteps}
              onOpenMySummary={nextStepsModal.isVisible.on}
              isOpenNextStepsDisabled={!isAllowToEditSummaryOrNextSteps}
              openNextStepsTooltip={nextStepsModal.openNextStepsTooltip}
              onCheck={nextStepsModal.onCheck}
            />
            <Attachments
              attachments={watch('attachments')}
              onDelete={attachmentHook.onDelete}
              onUpload={attachmentHook.onUpload}
              isAttachmentHandleDisabled={!isAllowToEditSummaryOrNextSteps}
              disabledAttachmentUploadToolTip={attachmentHook.onDisabledAttachmentUploadToolTip}
              isAdmin={isAdmin}
            />
            <EndOfTheReport>
              <Divider />
              <div className="title">
                <Trans>End of the report</Trans>
              </div>
              <Divider />
            </EndOfTheReport>
            {isDigitalSign && (
              <Signatures
                employeeId={userReview?.createdFor}
                guests={userReview?.guests}
                coaches={userReview?.coaches}
                signatures={userReview?.signatures}
                status={userReview?.status}
                isAllowToSign={isAllowToSign}
                onSign={signatureModal.isVisible.on}
                onReset={signatureModal.onReset}
                showResetAll={isAllowToResetSignatures}
              />
            )}
          </>
        }
      />
      {signatureModal.isVisible.value && isDigitalSign && (
        <SignatureModal
          formMethods={formMethods}
          signatureFormMethods={signatureModal.signatureFormMethods}
          userId={formMethods.watch('employee.id')}
          onSubmit={signatureModal.onSubmit}
          onClose={signatureModal.isVisible.off}
          showSettings={isEmployee}
        />
      )}
      {summaryModal.isVisible.value && isAllowToEditSummaryOrNextSteps && (
        <SummaryModal
          formMethods={summaryModal.formMethods}
          onClose={() => {
            summaryModal.autoSaveState.flush();
            summaryModal.isVisible.off();
          }}
          autoSaveState={summaryModal.autoSaveState}
          updateSummaryStatus={summaryModal.updateSummaryStatus}
          isShowKebabMenu={!isNotKebabMenuOnSummaryEnabled}
          isSummariesLoading={summaryModal.isSummariesLoading.value}
        />
      )}
      {nextStepsModal.isVisible.value && isAllowToEditSummaryOrNextSteps && (
        <NextStepsModal
          editorFormMethods={nextStepsModal.editorFormMethods}
          onClose={nextStepsModal.isVisible.off}
          participants={participants}
          onSubmit={nextStepsModal.onSubmit}
          onDelete={nextStepsModal.onDelete}
          onCheck={nextStepsModal.onCheck}
          nextSteps={nextStepsModal.nextSteps}
          editStepId={nextStepsModal.editStepId}
          setEditStepId={nextStepsModal.setEditStepId}
        />
      )}
      {showNominatePeersModal && nominatePeersTask && (
        <NominatePeers
          taskId={nominatePeersTask.id}
          closeModal={(isSubmit) => closeNominatePeersModal(isSubmit)}
        />
      )}
      {showSelectCoachesModal && (
        <SelectCoachesModal
          userReviewType={userReview?.type}
          user={employee}
          onSave={onAddCoaches}
          onClose={closeSelectCoachesModal}
          usersToHide={[employee?.id, ...watch('coaches')].filter((i) => i)}
        />
      )}
      {showSelectGuestsModal && (
        <SelectGuestsModal
          onSave={onAddGuests}
          onClose={closeSelectGuestsModal}
          usersToHide={[employee?.id, ...watch('guests')].filter((i) => i)}
        />
      )}
      {showPlanCalendarEventModal && (
        <PlanCalendarEventModal userReviewId={userReviewId} onClose={closePlanCalendarEventModal} />
      )}
      {$openEditModal.value && selectedRating && (
        <QuestionEditModal
          userReview={userReview}
          onClose={onEditModalClose}
          rating={selectedRating}
          updateReviewRating={onUpdateReviewRating}
        />
      )}
    </>
  );
};

export { ReviewDashboardUserForm };
