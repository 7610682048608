import { ROLES } from '@learned/constants';
import {
  IFile,
  IGetUserReviewsByIdRequest,
  IGetUserReviewsRequest,
  IUserReview,
  IUserReviewSignature,
} from '@learned/types';
import FileSaver from 'file-saver';

import { IServerRoute, IGetServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

export function getUserReviews(
  body: IGetUserReviewsRequest,
  { signal }: { signal?: AbortSignal } = {},
) {
  // @ts-ignore
  return cloudRequest(serverRoutes.userReviews.items as IServerRoute, {}, body, { signal });
}

export function getUserReview(userReviewId: IUserReview['id'], data?: IGetUserReviewsByIdRequest) {
  return cloudRequest((serverRoutes.userReviews.item as IGetServerRoute)(userReviewId), {}, data);
}

export function deleteUserReviewById(userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.deleteById as IGetServerRoute)(userReviewId),
    {},
    {},
  );
}

export function archiveUserReviewById(userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.archiveById as IGetServerRoute)(userReviewId),
    {},
    {},
  );
}

export function unarchiveUserReviewById(userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.unarchiveById as IGetServerRoute)(userReviewId),
    {},
    {},
  );
}

export async function downloadUserReviewPdf(
  userReviewId: IUserReview['id'],
  reviewName: string,
  userName: string,
): Promise<void> {
  return cloudRequest(
    // @ts-ignore
    serverRoutes.generatePDF.getUserReviewPDF(userReviewId),
    {},
    {},
    // @ts-ignore
    { arrayBuffer: true },
  ).then((response) => {
    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `${reviewName}-${userName}-report.pdf`);
    }
  });
}

export function updatePeers(
  userReviewId: IUserReview['id'],
  data: {
    delete: IUserReview['peers'];
  },
) {
  return cloudRequest(
    (serverRoutes.userReviews.updatePeers as IGetServerRoute)(userReviewId),
    {},
    data,
  );
}

export function updateCoaches(
  userReviewId: IUserReview['id'],
  data: {
    add?: IUserReview['coaches'];
    delete?: IUserReview['coaches'];
  },
) {
  return cloudRequest(
    (serverRoutes.userReviews.updateCoaches as IGetServerRoute)(userReviewId),
    {},
    data,
  );
}

export function updateGuests(
  userReviewId: IUserReview['id'],
  data: {
    add?: IUserReview['guests'];
    delete?: IUserReview['guests'];
  },
) {
  return cloudRequest(
    (serverRoutes.userReviews.updateGuests as IGetServerRoute)(userReviewId),
    {},
    data,
  );
}

export function deleteUserReviewAttachment(fileId: IFile['id'], userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.deleteUserReviewAttachment as IGetServerRoute)(userReviewId),
    {
      file: fileId,
    },
  );
}

export function uploadUserReviewAttachment(file: File, userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.uploadUserReviewAttachment as IGetServerRoute)(userReviewId),
    {},
    { file },
    // @ts-ignore
    { sendAsFormData: true },
  );
}

export function addSignature(userReviewId: string, body: IUserReviewSignature) {
  return cloudRequest(
    (serverRoutes.userReviews.addSignature as IGetServerRoute)(userReviewId),
    null,
    body,
  ) as Promise<{
    code: number;
    data: { userReview: IUserReview };
  }>;
}

export function deleteSignatures(userReviewId: string) {
  return cloudRequest(
    (serverRoutes.userReviews.deleteSignatures as IGetServerRoute)(userReviewId),
  ) as Promise<{
    code: number;
  }>;
}

export function getUserReviewReportStatus(userReviewId: string, forceRole?: ROLES) {
  return cloudRequest(
    (serverRoutes.userReviews.getUserReviewReportStatus as IGetServerRoute)(userReviewId),
    {},
    {},
    // Need to ignore this because cloudRequest is not a ts file
    // @ts-ignore
    { ...(forceRole && { forceRole }) },
  );
}

export function sendReportToIntegration(
  userReviewId: string,
  integrationSettingsId: string,
  forceRole?: ROLES,
) {
  return cloudRequest(
    (serverRoutes.userReviews.sendReportToIntegration as IGetServerRoute)(
      userReviewId,
      integrationSettingsId,
    ),
    {},
    {},
    // Need to ignore this because cloudRequest is not a ts file
    // @ts-ignore
    { ...(forceRole && { forceRole }) },
  );
}

// Alex: old API we did not change it route
export function planConversation(
  userReviewId: IUserReview['id'],
  data: {
    startDate: string;
    endDate: string;
    includeLinkMeeting: IUserReview['includeLinkMeeting'];
  },
  isReturnCalendarEvent: boolean,
) {
  return cloudRequest(
    (serverRoutes.userReviews.planConversation as IGetServerRoute)(userReviewId),
    null,
    {
      startDate: data.startDate,
      endDate: data.endDate,
      includeLinkMeeting: data.includeLinkMeeting,
      isReturnCalendarEvent,
    },
  );
}
